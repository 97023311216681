import React from "react";
import styles from "./Input.module.scss";

interface InputProps {
  label: string;
  disabled: boolean;
  type: string;
  value: string;
  id: string;
  onChange: (e: any) => void;
}

const Input: React.FC<InputProps> = ({
  label,
  disabled,
  type,
  value,
  id,
  onChange,
}) => {
  return (
    <div className={styles.inputBox}>
      <input
        id={id}
        type={type}
        placeholder=" "
        name={label}
        value={value}
        onChange={onChange}
        required
        disabled={disabled ? true : false}
      />
      <span>{label}</span>
    </div>
  );
};

export default Input;
