import { Link } from "react-router-dom";
import styles from "./mainPage.module.scss";

export default function MainPage() {
  return (
    <div className={styles.pageLayout}>
      Wanna split some expences?
      <div className={styles.createEvent}>
        <Link className={styles.createEventButton} to={"/event/new"}>
          CREATE EVENT
        </Link>
      </div>
    </div>
  );
}
