"use client";
import styles from "./addMembers.module.scss";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Button from "../button/button";
import Input from "../Input/Input";

export default function AddMembers() {
  const navigation = useNavigate();
  const { eventId } = useParams();

  async function addMembers() {
    const data = {
      eventId: eventId,
      members: [] as string[],
    };
    arr.forEach((element) => {
      if (element.value.length > 0) {
        data.members.push(element.value);
      }
    });
    await fetch("http://localhost:8080/members", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log("zenius");
    navigation(`/event/${eventId}`);
  }

  async function getMembers() {
    const req = await fetch(`http://localhost:8080/members/${eventId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await req.json();
    console.log(response);
    if (JSON.stringify(response) !== "[]") {
      console.log("bananas");
      console.log(response);
      navigation(`/event/${eventId}`);
    }
    return await response;
  }

  useEffect(() => {
    getMembers();

    console.log("arbuzas");
  });

  const inputArr = [
    {
      value: "",
    },
  ];

  const [arr, setArr] = useState(inputArr);

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          type: "text",
          id: s.length + 1,
          value: "",
        },
      ];
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const index = Number(e.target.id);
    console.log(arr);

    const updatedArr = [...arr];
    updatedArr[index].value = newValue;

    setArr(updatedArr);
  };

  const removeMember = (index: number) => {
    if (index !== -1) {
      const updatedArr = [...arr];
      updatedArr.splice(index, 1);
      setArr(updatedArr);
    } else {
      console.log("Object with ID not found.");
    }
  };

  return (
    <main className={styles.pageLayout}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          {/* <div className={`${styles.debeselis} ${styles.d2}`}></div> */}
          <div className={`${styles.debeselis} ${styles.d3}`}></div>
          <h2>Enter the participants</h2>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputContainer}>
            {arr.map((item, i) => {
              return (
                <div className={styles.inputRow} key={i}>
                  <Input
                    label={`Member ${i + 1}`}
                    disabled={false}
                    key={i}
                    type="text"
                    id={String(i)}
                    value={arr[i].value}
                    onChange={(e: any) => handleChange(e)}
                  />
                  <div onClick={() => removeMember(i)}>
                    <img
                      height={20}
                      width={20}
                      alt="trash-icon"
                      src="/trash.svg"
                      className={styles.removeMember}
                    ></img>
                  </div>
                </div>
              );
            })}
            <Button text="Add new member" onClick={() => addInput()} />
          </div>
          <Button text="Next" onClick={() => addMembers()} />
        </div>
      </div>
    </main>
  );
}
