import { Link } from "react-router-dom";
import styles from "./header.module.scss";

export default function Header() {
  return (
    <nav className={styles.header}>
      <Link to="/" className={styles.title}>
        ZOOsum
      </Link>
    </nav>
  );
}
