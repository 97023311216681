"use client";
import { useState } from "react";
import Button from "../../components/button/button";
import Input from "../../components/Input/Input";
import styles from "./createEvent.module.scss";
import { useNavigate } from "react-router";

export default function CreateNewEvent() {
  const navigate = useNavigate();

  async function createEvent(eventName: string) {
    const req = await fetch("http://localhost:8080/events", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: eventName }),
    });
    const response = await req.json();
    console.log(response.eventId);
    navigate(`${response.eventId}`);
  }

  const [eventName, setEventName] = useState("");

  return (
    <main className={styles.pageLayout}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          {/* <div className={`${styles.debeselis} ${styles.d2}`}></div> */}
          {/* <div className={`${styles.debeselis} ${styles.d3}`}></div> */}
          <h2>Create your event and split the expences</h2>
        </div>
        <div className={styles.inputBox}>
          {/* <img
            className={styles.image}
            alt="marketing image"
            src="/marketing.png"
            
          ></img> */}

          <img
            src="/cloud.svg"
            alt="cloud"
            className={`${styles.debeselis} ${styles.svg}`}
          ></img>

          <div className={styles.inputContainer}>
            <Input
              label="Event name"
              disabled={false}
              type="text"
              value={eventName}
              id="eventName"
              onChange={(e: any) => setEventName(e.target.value)}
            />
            <Button text="Next" onClick={() => createEvent(eventName)} />
          </div>
        </div>
      </div>
    </main>
  );
}
