import "./App.scss";
import Header from "./components/header/header";
import { Route, Routes } from "react-router";
import CreateNewEvent from "./components/createEvent/createEvent";
import AddMembers from "./components/createEvent/addMembers";
import MainPage from "./components/mainPage/mainPage";
import EventView from "./components/eventView/eventView";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/event">
          <Route path="new" element={<CreateNewEvent />}></Route>
          <Route path="new/:eventId" element={<AddMembers />}></Route>

          <Route path=":eventId" element={<EventView />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
