import React from "react";
import styles from "./button.module.scss";

interface ButtonProps {
  text: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <button
      type="button"
      className={styles.customButton} // Apply the custom class
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
