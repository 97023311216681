"use client";
import { useState, useEffect } from "react";
import styles from "./eventView.module.scss";
import { useParams } from "react-router";

interface MembersProps {
  eventId: string;
  name: string;
}

interface EventProps {
  id: number;
  name: string;
  group_size: number;
  creation_date: Date;
  owner: string;
  event_id: string;
}

const EventView = () => {
  const { eventId } = useParams();
  const [members, setMembers] = useState<string[]>([]);
  const [eventName, setEventName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getMembers() {
      setLoading(true);
      try {
        const req = await fetch(`http://localhost:8080/members/${eventId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const response = await req.json();
        setLoading(false);
        return response;
      } catch (error) {
        console.error("Error fetching members:", error);

        return [];
      }
    }

    async function getEvent() {
      setLoading(true);
      try {
        const req = await fetch(`http://localhost:8080/events/${eventId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const response = await req.json();
        setLoading(false);
        return response;
      } catch (error) {
        console.error("Error fetching members:", error);
        setLoading(false);
        return [];
      }
    }
    const fetchMembers = async () => {
      const response = await getMembers();
      const newMembers = response.map((item: MembersProps) => item.name);
      setMembers(newMembers);

      const eventResponse: EventProps = await getEvent();
      setEventName(eventResponse.name);
    };

    fetchMembers();
  }, [eventId]);

  return (
    <main>
      {loading ? (
        <div className={styles.loader}></div>
      ) : (
        <div className={styles.pageLayout}>
          <div className={styles.titleContainer}>
            Event <div className={styles.eventName}>{eventName}</div>
          </div>
          <div className={styles.membersContainer}>
            <h3>Members:</h3>
            {members.map((member) => (
              <div className={styles.member} key={member}>
                {member}
              </div>
            ))}
            <div className={styles.member}>+ Add Member</div>
          </div>
        </div>
      )}
    </main>
  );
};

export default EventView;
